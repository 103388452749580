import { Button } from "@mui/material";
import React from "react";
import "../../Assets/css/halaStyle.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import ringSetting from "../../Assets/images/home/ring-setting.webp";
import settingDiamonds from "../../Assets/images/home/setting-diamonds.webp";
import finalRing from "../../Assets/images/home/final-ring.webp";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BsArrowRight } from "react-icons/bs";
import RingVideo from "../../Assets/ring-video1.mp4";

const StudsBuilder = () => {
  const history = useHistory();
  return (
    <>
      <Container className="hl_custome_container  rcs_studs_builder">
        <div className="rcs_ringbuilder_home">
          <Row>
            <Col sm={12} md={12} lg={6}>
              <div className="hl_ring_builder_img"></div>
            </Col>
            <Col sm={12} md={12} lg={6} className="d-flex align-items-center">
              <div class="ddov_eng_ring_text_box">
                <h5>Create Your Own</h5>
                <h3>Diamond Studs</h3>
                <p>
                  Design Diamond Studs That Reflects Your Style and Personality.
                </p>
                <div class="hl_ring_builder_btn_box">
                  <button
                    type="button"
                    class="ddov_btn_gray btn btn-primary"
                    onClick={() => history.push("/stud-diamonds")}
                  >
                    Create Diamond Studs{" "}
                    <span>
                      <BsArrowRight />
                    </span>
                  </button>
                  {/* <div className="hl_text_or">
                                            <p>Or</p>
                                        </div>
                                        <button type="button" class="ddov_btn_outline btn btn-primary" onClick={() => history.push("/diamonds")}>
                                            Start with a Diamond <span><BsArrowRight /></span>
                                        </button> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default StudsBuilder;
