import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import HdVideo from "../../Assets/images/hala-london-img/hd-video.png"
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import HomeVideo from '../../Assets/hala-home-page-video1.mp4' 

const HqVideo = () => {
  const history = useHistory()
  return (
    <>
       <div className='hl_hq_video_section'>
<Container className='hl_custome_container '>
      <Row>
           <Col sm={12} md={12} lg={6} className='d-flex align-items-center justify-content-center'>
<div className='hl_hq_video_box'>
{/* <Image src={HdVideo} alt="hq Video Img" className="img-fluid"/> */}

<video width="100%" height="300" autoplay="" muted loop playsinline preload="metadata">
	  <source src={HomeVideo} type="video/mp4"/>
  </video>
</div>
           </Col>
           <Col sm={12} md={12} lg={6} className='d-flex align-items-center'>
            <div className='hl_hq_text_box'>
           <h6>Explore Diamonds with</h6>
                <h2 className='text-white text-left'>HQ 360 videos</h2>
                <p className='text-white text-left'>We get it - you want to know every detail of your diamond. And so you should. That’s why we’ve created a live diamond feed, complete with detailed 360 videos. Find your perfect diamond.</p>
              <div className='hl_video_two_btn'>
                <button className='hl_btn_outline_white' onClick={()=> history.push("/diamonds")}>Shop Natural Diamond</button>
                <button className='hl_btn_outline_white_bg' onClick={()=> history.push("/diamonds/lab")}>Shop Lab Grown Diamond</button>
              </div>
            </div>
           </Col>
      </Row>
</Container>
       </div>
    </>
  )
}

export default HqVideo
