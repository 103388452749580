import React from "react";
import "../../Assets/css/halaStyle.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Ring from "../../Assets/images/Ring.png";
import Studs from "../../Assets/images/Studs.png";

const Ringbuilderhome = () => {
  const history = useHistory();
  return (
    <>
      <div className="rcs_ringbuilder_home py-5">
        <Container className="rcs_custom_home_container">
          {/* <Row className="w-100 m-auto">
            <Col>
              <div
                className="rcs_ringbuilder_top_head mb-5 mb-md-2"
                data-aos="fade-up"
              >
                <h2>Create your own</h2>
                <p> Diamond ring </p>
              </div>
            </Col>
          </Row> */}
          {/* <Row className='w-100 m-auto justify-content-center'>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className='rcs_diamonds_ring'>
                                <Row className='w-100 align-items-center justify-content-center mt-5 mt-md-4 mr-0 ml-0'>                                    
                                    <Col xs={12} sm={12} md={5} className="p-0">
                                        <div className='rcs_diamonds_ring_content text-center' data-aos="fade-up" data-aos-offset="300"  data-aos-duration="700" data-aos-easing="ease-in-sine">
                                            <h2>Step 1</h2>
                                            <p>Choose a setting</p>
                                            <LazyLoadImage src={ringSetting} onClick={() => history.push('/ringsettings')} alt="ring setting"/>
                                            <Button variant="contained" onClick={() => history.push('/ringsettings')} className='rcs_ringbuilder_button br-0'>start with a setting</Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={2}>
                                        <div className='rcs_diamonds_ring_add_content text-center' data-aos="fade-up" data-aos-easing="ease-in-sine"  data-aos-duration="900">
                                            <h3>+</h3>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} className="p-0">
                                        <div className='rcs_diamonds_ring_content text-center' data-aos="fade-up" data-aos-offset="300" data-aos-duration="1100" data-aos-easing="ease-in-sine">
                                            <h2>Step 2</h2>
                                            <p>choose a diamond</p>
                                            <LazyLoadImage src={settingDiamonds} onClick={() => history.push('/diamonds')} alt='diamond'/>
                                            <Button variant="contained" onClick={() => history.push('/diamonds')} className='rcs_ringbuilder_button'>start with a diamond</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12}  lg={2} xl={1}>
                            <div className='rcs_diamonds_ring_equal d-flex align-items-center justify-content-center position-relative h-100' data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine"  data-aos-duration="1400">
                                <h3>=</h3>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='rcs_diamonds_ring_final'  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine"  data-aos-duration="1400">
                                <LazyLoadImage src={finalRing} alt='diamond'/>
                            </div>
                        </Col>
                    </Row> */}
          <Row className="justify-content-center">
            <Col xxl={9}>
            <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="shadow pb-4 pt-md-0 pt-4 px-4 h-100">
                <div className="hl_ring_builder_img text-center">
                  {/* <Image
                src={require("../../Assets/images/hala-london-img/diamond-ring.webp")}
                className="img-fluid"
                alt="Ring builder ring image"
              /> */}
                  {/* <video width="100%" height="500" autoplay="" muted loop playsinline preload="metadata">
                <source src={RingVideo} type="video/mp4"/>
              </video> */}
                  <Image className="img-fluid" src={Ring} alt="" />
                </div>

                <div class="ddov_eng_ring_text_box text-center">
                  <h5>Create Your Dream</h5>
                  <h3>Diamond Ring</h3>
                  <p>
                    Design a Diamond Ring That Reflects Your Style and
                    Personality.
                  </p>
                  <div class="hl_ring_builder_btn_box ">
                    <button
                      type="button"
                      class="ddov_btn_gray btn btn-primary"
                      onClick={() => history.push("/ringsettings")}
                    >
                      Start with a Setting{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                    {/* <div className="hl_text_or"><p>Or</p></div> */}
                    <button
                      type="button"
                      class="ddov_btn_outline btn btn-primary"
                      onClick={() => history.push("/diamonds")}
                    >
                      Start with a Diamond{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} lg={6} className="">
              <div className="shadow pb-md-4 pt-md-0 pt-4 px-4 mt-md-0 mt-4 h-100">
                <div className="hl_ring_builder_img text-center">
                  <Image className="img-fluid" src={Studs} alt="" />
                </div>

                <div class="ddov_eng_ring_text_box text-center">
                  <h5>Create Your Dream</h5>
                  <h3>Diamond Studs</h3>
                  <p>
                    Design a Diamond Studs That Reflects Your Style and
                    Personality.
                  </p>
                  <div class="hl_ring_builder_btn_box ">
                    <button
                      type="button"
                      class="ddov_btn_gray btn btn-primary"
                      onClick={() => history.push("/stud-settings/diamonds")}
                    >
                      Start with a Setting{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                    {/* <div className="hl_text_or"><p>Or</p></div> */}
                    <button
                      type="button"
                      class="ddov_btn_outline btn btn-primary"
                      onClick={() => history.push("/stud-diamonds")}
                    >
                      Start with a Diamond{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            </Row>
            </Col>
          
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Ringbuilderhome;
